import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image";
import WaveIcon from "icons/wave-2.svg"

// import app components
import {
  Button,
  Edges,
  Hero,
  Spacer,
  Textarea,
  VideoImage
} from "components"

export default function Boatlanding(props) {
  const {
    data: {
      page: {
        templateBoatlanding: {
          boatLandingFields: {
            boatSelector2: {
              boatSelectorBoats2,
              boatSelectorContent2,
              boatSelectorTitle2,
            },
            boatLandingHero: { image, headline },
            boatLandingContent: {
              contentMain,
              contentImage,
              contentVideo,
              imageOrVideo,
              button
            },
            boatLandingFeature: { featureMain, featureImage }
          }
        }
      }
    }
  } = props

  const truncate = (theText, length) => {
    if (!theText) {
      return ""
    }

    if (!length) {
      length = 150;
    }

    return theText.length > length ? theText.substring(0, (length - 20)) + "..." : theText
  }

  return <>
    <Hero border={true} image={image} headline={headline} />

    <ContentContainer>
      <Edges size="cont">
        <Spacer pt={{xs: 20, md: 80}} mb={{xs: 30, md: 0}} />
        <StyledGrid container spacing={1}>
          <Grid item xs={12} md={5}>
            {imageOrVideo === "image" && (
              <GatsbyImage image={contentImage?.localFile?.childImageSharp?.gatsbyImageData} />
            )}
            {imageOrVideo === "video" && (
              <VideoImage
                image={contentImage}
                responsive={true}
                youtubeUrl={contentVideo}
              />
            )}
          </Grid>
          <Grid item md={1} />
          <Grid item md={5}>
            {!!contentMain && (
              <Textarea
                content={contentMain}
              />
            )}
            {!!button && (
              <Spacer mt={{ xs: 30 }} mb={{ xs: 30, md: 0 }}>
                <Button
                  to={button.url}
                  children={button.title}
                  variant="outlined"
                  color="secondary"
                />
              </Spacer>
            )}
          </Grid>
          <Grid item md={1} />
        </StyledGrid>
        <Spacer pb={{xs: 0, md: 80}} />
      </Edges>
    </ContentContainer>

    <FeatureContainer>
      <Edges size="cont">
        <Spacer pt={80} />
        <Grid container spacing={1}>
          <Grid item md={2} />
          <Grid item md={5}>
            {!!featureMain && (
              <Textarea
                content={featureMain}
              />
            )}
          </Grid>
          <Grid item md={1} />
          <Grid item md={4}>
            {!!featureImage && (
              <GatsbyImage image={featureImage?.localFile?.childImageSharp?.gatsbyImageData} />
            )}
          </Grid>
        </Grid>
        <Spacer pb={80} />
      </Edges>
    </FeatureContainer>

    <WaveContainer>
      <WaveIcon />
    </WaveContainer>

    <ModelContainer>
      <Edges size="cont">
        <Spacer pt={60} pb={60}>
          <Grid container>
            <Grid item xs={0} md={2} />
            <Grid item md={5}>
              {!!boatSelectorTitle2 && (
                <Typography
                  variant="h2"
                  children={boatSelectorTitle2}
                />
              )}
              {!!boatSelectorContent2 && (
                <Textarea
                  content={boatSelectorContent2}
                />
              )}
            </Grid>
          </Grid>
        </Spacer>
        <Spacer pb={60}>
          <Grid container spacing={1}>
            {boatSelectorBoats2 && boatSelectorBoats2.map((o, i) => {
              return (
                <GridMargin key={i} item xs={12} md={4} lg={3}>
                  <Link to={o.uri}>
                    <ImageContainer>
                      <GatsbyImage
                        image={o.acf.introduction.boatFamilyImage?.localFile?.childImageSharp?.gatsbyImageData ?
                        o.acf.introduction.boatFamilyImage?.localFile?.childImageSharp?.gatsbyImageData :
                        o.acf.introduction.image?.localFile?.childImageSharp?.gatsbyImageData ?
                        o.acf.introduction.image?.localFile?.childImageSharp?.gatsbyImageData : null}
                        alt={
                          o.acf.introduction.boatFamilyImage?.altText ?
                          o.acf.introduction.boatFamilyImage?.altText :
                          o.acf.introduction.image?.altText ?
                          o.acf.introduction.image?.altText : ""
                        } />
                    </ImageContainer>
                  </Link>
                  <Spacer p={{xs: 15, md: 30}}>
                    <Typography
                      variant="h2"
                      children={o.title}
                    />
                    <Spacer pt={15} pb={15}>
                      <Textarea
                        content={truncate(o.acf.introduction.text, 175)}
                      />
                    </Spacer>
                    <Button
                      to={o.uri}
                      children={"View Model"}
                      variant="outlined"
                      color="primary"
                    />
                  </Spacer>
                </GridMargin>
              );
            })}
          </Grid>
        </Spacer>
      </Edges>
    </ModelContainer>
  </>;
}

const ContentContainer = styled.div`
  background: #000;
  color: white;
`

const FeatureContainer = styled.div`
  background: radial-gradient(77.11% 77.11% at 7.58% -22.46%, #252525 0%, #000000 100%);
  color: white;
  margin-top: -1px;
`

const ImageContainer = styled.div`
  height: 200px;

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }
`

const StyledGrid = styled(Grid)`
  flex-flow: column-reverse wrap;

  @media (min-width: 768px) {
    flex-flow: row wrap;
  }
`

const ModelContainer = styled.div``

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-62px);

  @media (min-width: 960px) {
    width: 50%;
  }

  svg {
    max-width: 100%;
    width: auto;
    height: 150px;
  }
`

const GridMargin = styled(Grid)`
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`

export const CollectionQuery = graphql`query Boatlanding($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateBoatlanding {
      boatLandingFields {
        boatSelector2 {
          boatSelectorTitle2
          boatSelectorContent2
          boatSelectorBoats2 {
            ... on WpBoat {
              id
              title
              uri
              acf {
                introduction {
                  text
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                      }
                    }
                  }
                  boatFamilyImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        boatLandingHero {
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        boatLandingContent {
          contentMain
          imageOrVideo
          contentImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          contentVideo
          button {
            url
            target
            title
          }
        }
        boatLandingFeature {
          featureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          featureMain
        }
      }
    }
  }
  boatLandingCategory: allWpCategory {
    nodes {
      databaseId
      name
    }
  }
}
`
